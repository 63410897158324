import React from 'react';
import { styled, theme } from '../../../../Theme';
import FooterList from './FooterList/FooterList';
import IFooter from './Footer.interface';
import useQueryFooter from './useQueryFooter';
import { media } from '@glitz/core';
import { mediaQuery } from '../../../../Theme/mediaQueries';
import { auto } from '@popperjs/core';

type PropTypes = {
  initData: IFooter;
};

function Footer({ initData }: PropTypes) {
  const [
    {
      footerFarLeftColumn,
      footerLeftColumn,
      footerRightColumn,
      footerFarRightColumn,
      footerBottomColumn,
    },
  ] = useQueryFooter(initData);

  return (
    <FooterElm>
      <FooterContentContainer>
        <FooterListWrapper>
          <FooterList footerColumn={footerFarLeftColumn} isFirst={true} />
          <FooterList footerColumn={footerLeftColumn} />
          <FooterList footerColumn={footerRightColumn} />
          <FooterList footerColumn={footerFarRightColumn} />
          <FooterList footerColumn={footerBottomColumn} isLast={true} />
        </FooterListWrapper>
      </FooterContentContainer>
    </FooterElm>
  );
}

const FooterElm = styled.footer({ backgroundColor: theme.lighterDarkGray });

const FooterContentContainer = styled.div({
  maxWidth: theme.contentMaxWidth,
  padding: { x: '7.5px' },
  margin: { x: 'auto' },
});

const FooterListWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: {
    y: theme.spacing(8),
  },
  ...media(mediaQuery.mediaMinLarge, {
    padding: { top: theme.spacing(10), bottom: theme.spacing(12) },
  }),
});

export default Footer;
