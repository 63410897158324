import * as typography from './typography';
import * as color from './colors';
import * as spacing from './spacing';
import * as zIndex from './zindex';
import * as animation from './animation';
import * as shadows from './shadow';
import * as mediaQueries from './mediaQueries';
import { styled as GlitzStyled } from '@glitz/react';

export const theme = {
  ...typography,
  ...color,
  ...zIndex,
  ...animation,
  ...mediaQueries,
  ...spacing,
  ...shadows,
};

export const styled = GlitzStyled;
