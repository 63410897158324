/**
  Font sizes
  **/
export const tau = '10px';
export const alpha = '12px';
export const beta = '14px';
export const gamma = '16px';
export const delta = '18px';
export const epsilon = '20px';
export const zeta = '24px';
export const psi = '26px';
export const eta = '28px';
export const theta = '36px';
export const omikron = '44px';
export const iota = '48px';
export const kappa = '64px';
export const lambda = '128px';

/**
 * Picutra
 */
export const headerMainLink = '13px';
export const productHeading = '32px';
export const headingDesktop = '40px';
export const subTitle = '15px';

/** 
  Line height
 **/
export const lineHeight = {
  none: 1,
  tight: 1.25,
  snug: 1.375,
  moreSnug: 1.43,
  normal: 1.5,
  relaxed: 1.625,
  moreRelaxed: 1.75,
  loose: 2,
};

/** 
  Letter spacing
 **/
export const letterSpacing = {
  normal: 0.32,
  normalWide: 0.6,
  nearWide: 0.7,
  wide: 0.8,
  moreWide: 0.96,
  nearMedium: 1.13,
  medium: 1.25,
  wider: 1.63,
  heading: 2,
  header: 3,
  largeHeader: 8,
};

/** 
  Font family
 **/

export const fontFamily = {
  main: 'Lato',
  fallback: 'Arial, Helvetica, sans-serif',
};

export const fontWeight = {
  lighter: 300,
  normal: 400,
  bold: 700,
  boldest: 900,
};
