import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { useKexLoadingCircle } from '../../Loading/KexLoadingCircle/KexLoadingCircle';
import { IS_PRODUCTION_ENV } from '../../Configs/EnvConfig';
import FetchHeader from './FetchHeader';
import IHeader from './Header.interface';
import { useAppSettingsData } from '../../AppSettingsProvider/AppSettingsProvider';
import useCurrentPage from '../../Hooks/useCurrentPage';

let hasMounted = false;
let url = '';
let litiumContext = '';

export default function(initialData: IHeader): [IHeader] {
  const [data, setData] = useState<IHeader>(initialData);
  const kexLoadingCircleDispatch = useKexLoadingCircle();
  const { languageRoute } = useAppSettingsData();
  const { pageId, channelId } = useCurrentPage();

  litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    productCategorySystemId: pageId,
    channelSystemId: channelId,
  });

  url = `/api/${languageRoute}/App/GetStandardHeader`;

  const queryData = useSWR<IHeader>(
    '/App/GetStandardHeader',
    () => FetchHeader(url, litiumContext),
    {
      initialData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch(!queryData.data ? 'add' : 'remove');
  }, [kexLoadingCircleDispatch, queryData.data]);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}

export async function updateHeaderData(header: IHeader) {
  mutate('/App/GetStandardHeader', header, false);
}

export async function updateHeader() {
  const res = await FetchHeader(url, litiumContext);

  if (res) {
    mutate('/App/GetStandardHeader', res, false);
  }
}
