import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { useKexLoadingCircle } from '../../../Loading/KexLoadingCircle/KexLoadingCircle';
import { IS_PRODUCTION_ENV } from '../../../Configs/EnvConfig';
import FetchFooter from './FetchFooter';
import IFooter from './Footer.interface';
import { useAppSettingsData } from '../../../AppSettingsProvider/AppSettingsProvider';
import useCurrentPage from '../../../Hooks/useCurrentPage';

let hasMounted = false;

export default function(initialData: IFooter): [IFooter] {
  const [data, setData] = useState<IFooter>(initialData);
  const kexLoadingCircleDispatch = useKexLoadingCircle();

  const { languageRoute } = useAppSettingsData();
  const { pageId, channelId } = useCurrentPage();

  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    productCategorySystemId: pageId,
    channelSystemId: channelId,
  });

  const url = `/api/${languageRoute}/App/GetStandardFooter`;

  const queryData = useSWR<IFooter>(
    '/App/GetStandardFooter',
    () => FetchFooter(url, litiumContext),
    {
      initialData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch(!queryData.data ? 'add' : 'remove');
  }, [kexLoadingCircleDispatch, queryData.data]);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}
