import React from 'react';
import { styled, theme } from '../../Theme';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import CloseIcon from '../Icons/CloseIcon';

function KexReporterModal() {
  const {
    translations: {
      'error/errorHeader': errorHeader,
      'error/errorRequestText': errorRequestText,
    },
  } = useAppSettingsData();

  return (
    <Root>
      <Header>{errorHeader}</Header>
      <CloseButton onClick={() => window.location.reload(true)}>
        <StyledCloseIcon />
      </CloseButton>
      <Text>{errorRequestText}</Text>
    </Root>
  );
}

export default KexReporterModal;

const Root = styled.div({
  width: '100%',
  backgroundColor: '#F5F5F5',
  padding: { x: '40px', y: '40px' },
  height: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.errorModal,
});

const StyledCloseIcon = styled(CloseIcon, {
  height: '32px',
  width: '32px',
  marginRight: '8px',
});

const CloseButton = styled.button({
  position: 'absolute',
  right: '47px',
  top: '47px',
});

const Header = styled.h2({
  fontSize: theme.great,
  fontWeight: theme.fontWeight.bold,
  marginBottom: '40px',
});

const Text = styled.p({
  fontSize: theme.gamma,
});
