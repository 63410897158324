export const animation = {
  timingFn: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
};

export const timings = {
  oneTenth: '100ms',
  oneFifth: '200ms',
  threeTenths: '300ms',
  oneThird: '333ms',
  twoFifths: '400ms',
  oneHalf: '500ms',
  threeFifths: '600ms',
  one: '1000ms',
  sevenFifths: '1400ms',
  oneAndAHalf: '1500ms',
  five: '5000ms',
};
