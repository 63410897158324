export const zIndex = {
  Notification: 9,
  Header: 100,
  HeaderMobile: 10,
  Flyout: 10,
  DropDown: 10,
  FlyoutLower: 5,
  Cart: 10,
  CartMobile: 4,
  Modal: 20,
  Overlay: 980,
  pageLoader: 990,
  errorModal: 1000,
};
