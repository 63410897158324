import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { IS_PRODUCTION_ENV } from '../Configs/EnvConfig';
import FetchUserState from './FetchUserState';
import UserState from '../Models/UserState.interface';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';

let hasMounted = false;
let url = '';

export default function(initialData: UserState): [UserState] {
  const [data, setData] = useState<UserState>(initialData);

  const { languageRoute } = useAppSettingsData();

  url = `/api/${languageRoute}/App/GetUserState`;

  const queryData = useSWR<UserState>(
    '/App/GetUserState',
    () => FetchUserState(url),
    {
      initialData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}

export async function updateUserState() {
  const res = await FetchUserState(url);
  if (res) {
    mutate('/App/GetUserState', res, false);
  }
}
