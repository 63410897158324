import { EventDispatcher, ON_KEX_ERROR } from './EventDispatcher';

export default function<P, T>(
  url: string,
  signal: AbortSignal,
  onComplete: (
    data: T,
    resolve: (value?: P | PromiseLike<P>) => void,
    reject: (reason?: any) => void,
    response: Response
  ) => void,
  litiumRequest: string = '',
  requestToken: string = ''
) {
  return new Promise<P>((resolve, reject) => {
    let response: Response;
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
        'litium-request-context': litiumRequest,
        RequestVerificationToken: requestToken,
      },
      signal,
    })
      .then(res => {
        response = res;
        if (res.ok || res.status === 404) {
          return res.json();
        } else {
          throw new Error(`API: ${res.statusText}`);
        }
      })
      .then(data => onComplete(data, resolve, reject, response))
      .catch(err => {
        if (
          err.name === 'AbortError' ||
          err === 'DOMException: The user aborted a request.'
        ) {
          resolve(undefined);
        } else {
          EventDispatcher.dispatch(ON_KEX_ERROR, err);
        }
      });
  });
}
