import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function CookieIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      height="50px"
      width="50px"
      viewBox="0 0 38 38"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5708 2.5482C10.7003 2.74958 8.89928 3.4001 7.32473 4.45218C5.4013 5.73737 3.90217 7.56407 3.01691 9.70127C2.13165 11.8385 1.90003 14.1902 2.35133 16.459C2.80263 18.7279 3.91658 20.8119 5.55233 22.4477C7.18807 24.0834 9.27213 25.1974 11.541 25.6487C13.8098 26.1 16.1615 25.8683 18.2987 24.9831C20.4359 24.0978 22.2626 22.5987 23.5478 20.6753C24.5858 19.1219 25.2329 17.3481 25.4434 15.5045C24.7801 15.5226 24.0943 15.4027 23.4721 15.1765C22.4887 14.8189 21.5057 14.1378 20.9214 13.0902C19.8505 13.0162 18.5297 12.7446 17.3821 11.9502C16.0086 10.9993 15.0408 9.41159 14.903 6.98767C14.1927 6.48665 13.5612 5.75306 13.1376 4.9059C12.7898 4.21026 12.5655 3.4027 12.5708 2.5482ZM6.14326 2.68399C8.41641 1.16512 11.0889 0.354431 13.8228 0.354431H15.298L14.8315 1.75397C14.5835 2.49785 14.696 3.26749 15.0396 3.95486C15.3914 4.65839 15.9439 5.18821 16.4249 5.42871L17.0127 5.7226V6.37975C17.0127 8.57232 17.7739 9.63496 18.5926 10.2017C19.4829 10.8181 20.642 10.9873 21.6202 10.9873H22.3866L22.629 11.7144C22.8535 12.388 23.4378 12.9012 24.1988 13.1779C24.9879 13.4649 25.733 13.4131 26.1068 13.2262L27.6456 12.4568V14.1772C27.6456 16.9111 26.8349 19.5836 25.316 21.8567C23.7971 24.1299 21.6383 25.9016 19.1125 26.9478C16.5868 27.994 13.8075 28.2677 11.1261 27.7344C8.44474 27.201 5.98176 25.8845 4.0486 23.9514C2.11545 22.0182 0.798962 19.5553 0.265607 16.8739C-0.267748 14.1925 0.00598913 11.4132 1.0522 8.88746C2.09842 6.36168 3.87012 4.20286 6.14326 2.68399Z"
        fill="inherit"
      />
      <path
        d="M22.3291 2.12658C22.3291 3.30106 21.377 4.25316 20.2025 4.25316C19.028 4.25316 18.0759 3.30106 18.0759 2.12658C18.0759 0.952103 19.028 0 20.2025 0C21.377 0 22.3291 0.952103 22.3291 2.12658Z"
        fill="inherit"
      />
      <path
        d="M20.9114 17.7215C20.9114 18.896 19.9593 19.8481 18.7848 19.8481C17.6103 19.8481 16.6582 18.896 16.6582 17.7215C16.6582 16.547 17.6103 15.5949 18.7848 15.5949C19.9593 15.5949 20.9114 16.547 20.9114 17.7215Z"
        fill="inherit"
      />
      <path
        d="M23.038 7.0886C23.038 7.87159 22.4032 8.50633 21.6202 8.50633C20.8372 8.50633 20.2025 7.87159 20.2025 7.0886C20.2025 6.30562 20.8372 5.67088 21.6202 5.67088C22.4032 5.67088 23.038 6.30562 23.038 7.0886Z"
        fill="inherit"
      />
      <path
        d="M13.8227 14.1772C13.8227 14.9602 13.188 15.5949 12.405 15.5949C11.622 15.5949 10.9873 14.9602 10.9873 14.1772C10.9873 13.3942 11.622 12.7595 12.405 12.7595C13.188 12.7595 13.8227 13.3942 13.8227 14.1772Z"
        fill="inherit"
      />
      <path
        d="M26.5823 9.56962C26.5823 10.1569 26.1062 10.6329 25.519 10.6329C24.9317 10.6329 24.4557 10.1569 24.4557 9.56962C24.4557 8.98238 24.9317 8.50633 25.519 8.50633C26.1062 8.50633 26.5823 8.98238 26.5823 9.56962Z"
        fill="inherit"
      />
      <path
        d="M11.6962 6.73417C11.6962 7.32141 11.2201 7.79746 10.6329 7.79746C10.0456 7.79746 9.56958 7.32141 9.56958 6.73417C9.56958 6.14693 10.0456 5.67088 10.6329 5.67088C11.2201 5.67088 11.6962 6.14693 11.6962 6.73417Z"
        fill="inherit"
      />
      <path
        d="M6.02526 13.8228C6.02526 14.41 5.54921 14.8861 4.96197 14.8861C4.37473 14.8861 3.89868 14.41 3.89868 13.8228C3.89868 13.2355 4.37473 12.7595 4.96197 12.7595C5.54921 12.7595 6.02526 13.2355 6.02526 13.8228Z"
        fill="inherit"
      />
      <path
        d="M11.6962 20.9114C11.6962 21.4986 11.2201 21.9747 10.6329 21.9747C10.0456 21.9747 9.56958 21.4986 9.56958 20.9114C9.56958 20.3242 10.0456 19.8481 10.6329 19.8481C11.2201 19.8481 11.6962 20.3242 11.6962 20.9114Z"
        fill="inherit"
      />
      <path
        d="M17.3671 22.3291C17.3671 22.9163 16.891 23.3924 16.3038 23.3924C15.7165 23.3924 15.2405 22.9163 15.2405 22.3291C15.2405 21.7419 15.7165 21.2658 16.3038 21.2658C16.891 21.2658 17.3671 21.7419 17.3671 22.3291Z"
        fill="inherit"
      />
      {/* </svg> */}
    </styled.Svg>
  );
}

export default styled(CookieIcon);
