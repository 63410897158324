import { useState, useEffect } from 'react';

import { IS_SERVER_CONTEXT } from '../Configs/EnvConfig';

function useMedia(query, initialServerValue = false) {
  const [state, setState] = useState(() =>
    IS_SERVER_CONTEXT ? initialServerValue : window.matchMedia(query).matches
  );

  useEffect(() => {
    const mql = window.matchMedia(query);
    const onChange = () => {
      setState(!!mql.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);
    return () => {
      mql.removeListener(onChange);
    };
  }, [query]);

  return state;
}

export default useMedia;
