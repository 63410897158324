import React from 'react';
import loadable from '@loadable/component';
import IHeader from './Header.interface';
import useQueryHeader from './useQueryHeader';
import useMedia from '../../Hooks/useMedia';
import { theme } from '../../../Theme';
import useCurrentPage from '../../Hooks/useCurrentPage';
import PageModelBase from '../PageModelBase.interface';

const MobileHeader = loadable(() => import('./MobileHeader/MobileHeader'));
const DesktopHeader = loadable(() => import('./DesktopHeader/DesktopHeader'));
const MobileMenu = loadable(() => import('../../MobileMenu/MobileMenu'));

type PropType = {
  initData: IHeader;
};

const HeaderContext = React.createContext({});

function Header({ initData }: PropType) {
  const [headerData] = useQueryHeader(initData);
  const { inEditMode } = useCurrentPage<PageModelBase>();
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  return (
    <HeaderContext.Provider value={headerData}>
      {/* episerver does not like loading both headers while in on-page-edit. When we do, it messes up some other CSS. */}
      {isDesktop || inEditMode ? <DesktopHeader /> : <MobileHeader />}
      {!isDesktop && <MobileMenu />}
    </HeaderContext.Provider>
  );
}

const useHeaderData = () => {
  return React.useContext(HeaderContext) as IHeader;
};

export { Header, useHeaderData };
