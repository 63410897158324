/** 
  Colors
*/
export const primary = '#484848';
export const secondary = '#F7EDDA';
export const secondaryText = '#F5F5F5';
export const white = '#FFFFFF';
export const breadText = '#404040';
export const heading = '#5C5C5C';
export const modal = '#A3A3A3';
export const hover = '#EDEDED';
export const primaryBackground = '#F8F8F8';

export const placeholder = '#AEAEAE';
export const sale = '#9C2929';
export const black = '#000000';
export const red = '#FF0000';
export const darkGray = '#292929';
export const lighterDarkGray = '#2E3035';
export const gray = '#3E3E3E';
export const lightGray = '#FAFAFA';
export const primaryBlue = '#0F4C81';
export const primaryDarkBlue = '#043662';
export const headerIconGray = '#C9CAC8';
export const veryLightGray = '#ECECEC';
export const linkColor = '#0664AA';
export const iconLightBlue = '#94B2CC';
export const grayLine = '#DCDCDC';
export const lightBlue = '#D9E4ED';
export const lightBlueHover = '#B7CBDC';
export const middleGray = '#767676';
export const inStockGreen = '#489C70';
export const notInStockRed = '#F44545';
export const purple = '#852A81';

/** Validation */

export const errorPrimary = '#feb2b2';
export const errorText = '#9a2626';

export const buttonPrimary = '#2E2E2E';

export const transparent = 'transparent';
