import React from 'react';
import loadable from '@loadable/component';

import KexPage from './Shared/KexPage/KexPage';
import { KexRouter } from './Shared/KexRouter/KexRouter';
import { Header } from './Shared/Models/Headers/Header';
import Footer from './Shared/Models/Footers/Footer/Footer';
import USPArea from './Shared/Models/USPArea/USPArea';
import { KexLoadingCircle } from './Shared/Loading/KexLoadingCircle/KexLoadingCircle';
import KexReporterOverlay from './Shared/KexReporterOverlay/KexReporterOverlay';
import KexNotificationOverlay from './Shared/Notifications/BasicNotification';
import ErrorBoundary from './Shared/ErrorBoundary/ErrorBoundary';
import useCurrentPage from '../Features/Shared/Hooks/useCurrentPage';
import AppInitModel from './Shared/Models/AppInitModel.interface';
import PageModelBase from './Shared/Models/PageModelBase.interface';
import { AppSettingsProvider } from './Shared/AppSettingsProvider/AppSettingsProvider';
import { UserContextProvider } from './Shared/UserContextProvider/UserContextProvider';
import Cookies from './Cookies/Cookies.js';
import { SignOut } from './LoginPage/Account';
import LoginPage from './LoginPage/LoginPage';

type PropType = {
  appInitData: AppInitModel;
};

const NotFoundPage = loadable(() =>
  import(/* webpackPrefetch: true */ './NotFoundPage/NotFoundPage')
);

const Content = ({ appInitData }: PropType) => {
  const { pageType } = useCurrentPage<PageModelBase>();

  return pageType === 'BlockPreviewPage' ? (
    <KexPage />
  ) : (
    <>
      <Header initData={appInitData.header} />
      <KexPage />
      {/* {appInitData.uspArea && <USPArea uspArea={appInitData.uspArea} />} */}
      <Cookies initData={appInitData} />

      <Footer initData={appInitData.footer} />
      <KexNotificationOverlay />
    </>
  );
};

function App({ appInitData }: PropType) {
  // setInterval(() => {
  //   SignOut = (languageRoute: string, requestToken: string) => {
  //     window.location.href = `${LoginPage}`;
  //   };
  // }, 1000 * 60);
  return (
    <AppSettingsProvider data={appInitData.appSettings}>
      <UserContextProvider data={appInitData.userState}>
        <KexReporterOverlay />
        <ErrorBoundary FallbackComponent={() => <NotFoundPage />}>
          <KexLoadingCircle>
            <KexRouter appInitData={appInitData} initUrl={appInitData.initUrl}>
              <Content appInitData={appInitData} />
            </KexRouter>
          </KexLoadingCircle>
        </ErrorBoundary>
      </UserContextProvider>
    </AppSettingsProvider>
  );
}

export default App;

// setInterval(() => {
//   SignOut(redirectUrl);
//   window.location.href = `${redirectUrl ? redirectUrl : data.redirectUrl}`;
// }, 1000 * 60);
