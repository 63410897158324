import React, { ErrorInfo } from 'react';

type PropType = {
  /* TODO define FallbackComponent types */
  FallbackComponent: any;
  children: React.ReactNode;
  onError?: (this: ErrorBoundary, error: Error, info: string) => void;
};

type StateType = {
  error?: Error;
  info: ErrorInfo;
};

class ErrorBoundary extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      error: undefined,
      info: {
        componentStack: '',
      },
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        /* istanbul ignore next: Ignoring ternary; can’t reproduce missing info in test environment. */
        onError.call(this, error, info ? info.componentStack : '');
      } catch (ignoredError) {}
    }

    if ((window as any).appInsights) {
      (window as any).appInsights.trackException(error);
    }

    this.setState({ error, info });
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, info } = this.state;

    if (error !== undefined) {
      return (
        <FallbackComponent
          componentStack={
            // istanbul ignore next: Ignoring ternary; can’t reproduce missing info in test environment.
            info ? info.componentStack : ''
          }
          error={error}
        />
      );
    }

    return children || null;
  }
}

export default ErrorBoundary;
