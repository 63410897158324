import React from 'react';
import UserState from '../Models/UserState.interface';
import useQueryUserState from './useQueryUserState';

type PropType = {
  children: React.ReactNode;
  data: UserState;
};

const UserContext = React.createContext({});

export const UserContextProvider = ({ children, data }: PropType) => {
  const [userStateData] = useQueryUserState(data);
  return (
    <UserContext.Provider value={userStateData ?? false}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserStateData = () => {
  return React.useContext(UserContext) as UserState;
};
