/** 
  Spacings
**/
export const none = '0px';
export const tiny = '1px';
export const smaller = '2px';
export const small = '4px';
export const medium = '8px';
export const large = '12px';
export const moreLarge = '16px';
export const larger = '22px';
export const huge = '24px';
export const nearGreat = '30px';
export const great = '32px';
export const nearVast = '42px';
export const vast = '44px';
export const gigantic = '48px';
export const greater = '52px';
export const massive = '64px';
export const enormous = '92px';

// More words we can use to define size - immense, wide, high, king-size

// helper to create spacings with base as 4. Kex-default = 4px. example: 40px padding = theme.spacing(10),
const BASE_SPACING = 4;
export const spacing = (multiplier: number) => BASE_SPACING * multiplier;

/** 
  Widths
**/

export const screenMaxWidth = 1440;
export const contentMaxWidth = 1130;
export const videoMaxWidth = 1024;
export const maxWidthSmall = 960;
export const contentResultWidth = 864;
export const maxWidthMobile = 768;
export const maxWidthContentPage = 670;
export const desktopQuickSearchWidth = 550;
export const blockMaxWidthMobile = 600;
export const mobileMenuMaxWidth = 310;
export const menuButtonWidth = 35;

/** 
  Heights
**/

export const heroMaxHeight = 420;
export const videoMaxHeight = 574;
export const standardBlockLargerImageHeight = 490;
export const standardBlockLargeImageHeight = 480;
export const standardBlockMediumImageHeight = 345;
export const thirdWidthBlockImageHeight = 312;
export const standardBlockSmallImageHeight = 250;
export const verySmallBlockImageHeight = 180;
export const mobileHeaderTopSectionHeight = 55;
export const mobileHeaderBottomSectionHeight = 70;
export const mobileHeaderHeight = 125;
export const desktopHeaderHeight = 140;
export const expandedDesktopHeaderHeight = 112;
export const menuButtonHeight = 28;

/*Pictura*/
export const desktopSearchModalMaxWidth = 1172;
export const desktopSearchModalMaxHeight = 634;
export const teaserImageDesktopHeight = 505;
export const bannerBlockMobileImageHeight = 295;
export const bannerBlockDesktopImageHeight = 465;
export const bannerBlockHeadingMargin = 35;
export const heroImageYMargin = 60;

/**
 * Image heights
 **/

export const thumbnailImageHeight = 128;
export const productImageMaxHeight = 575;
export const productImageMaxHeightMedium = 720;
export const productImageMaxHeightLarge = 736;
