import React from 'react';
import loadable from '@loadable/component';
import LoadingPageType from '../Loading/LoadingPageType/LoadingPageType';
import useCurrentPage from '../Hooks/useCurrentPage';
import PageModelBase from '../Models/PageModelBase.interface';

/* not possible to use full dynamic imports, in server context it wont be able to locate chunks */
const loadablePages = {
  StartPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../StartPage/StartPage')
  ),
  StandardPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../StandardPage/StandardPage')
  ),
  RecruitingPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../RecruitingPage/RecruitingPage')
  ),
  SegmentPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../SegmentPage/SegmentPage')
  ),
  ProductPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../ProductPage/ProductPage')
  ),
  BlockPreviewPage: loadable(() =>
    import('../../BlockPreview/BlockPreviewPage')
  ),
  CategoryPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../CategoryPage/CategoryPage')
  ),
  CheckoutPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../CheckoutPage/CheckoutPage')
  ),
  CartPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../Cart/CartPage')
  ),
  OrderConfirmationPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../OrderConfirmation/OrderConfirmationPage'
    )
  ),
  SearchPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../SearchPage/SearchPage')
  ),
  LoginPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../LoginPage/LoginPage')
  ),
  NotFoundPage: loadable(() => import('../../NotFoundPage/NotFoundPage')),
  NewsArchivePage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../NewsArchivePage/NewsArchivePage')
  ),
  NewsPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../NewsPage/NewsPage')
  ),
  PlanogramPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../PlanogramPage/PlanogramPage')
  ),
  MyPagesPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../MyPagesPage/MyPagesPage')
  ),
  MyPlanogramsPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../MyPlanogramsPage/MyPlanogramsPage'
    )
  ),
  MyPlanogramsSeasonalPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../MyPlanogramsSeasonalPage/MyPlanogramsSeasonalPage'
    )
  ),
};

const KexPage = () => {
  const { pageType } = useCurrentPage<PageModelBase>();

  const LoadablePage = (loadablePages as any)[pageType];
  return LoadablePage === undefined ? (
    <></>
  ) : (
    <LoadablePage fallback={<LoadingPageType />} />
  );
};

export default KexPage;
