import React from 'react';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import { StyledProps } from '@glitz/react';

type Divider = StyledProps;

const ContentDivider = ({ compose }: Divider) => {
  return <Base css={compose()} />;
};

const Base = styled.div({
  height: theme.tiny,
  width: theme.massive,
  margin: { y: theme.huge },
  backgroundColor: theme.primary,
  ...media(theme.mediaQuery.mediaMaxSmall, {
    margin: { top: theme.huge, bottom: theme.large },
  }),
});

export default styled(ContentDivider);
