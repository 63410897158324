import React from 'react';
import { styled, theme } from '../../../../../Theme';
import KexLink from '../../../../KexLink/KexLink';
import ILink from '../../../Link.interface';
import FooterColumn from '../FooterColumn.interface';
import { pseudo, media } from '@glitz/core';
import { mediaQuery } from '../../../../../Theme/mediaQueries';
import { red } from '../../../../../Theme/colors';

type PropTypes = {
  footerColumn: FooterColumn;
  isFirst?: boolean;
  isLast?: boolean;
};

function FooterList({ footerColumn, isFirst, isLast }: PropTypes) {
  return footerColumn ? (
    <Wrapper data-isfirst={isFirst} data-isLast={isLast}>
      {footerColumn.header && <Title>{footerColumn.header}</Title>}
      <List>
        {footerColumn.links &&
          footerColumn.links.map((item: ILink, index: number) => (
            <ListItem key={item.href && item.href + index}>
              <Link href={item.href}>{item.text}</Link>
            </ListItem>
          ))}
      </List>
      <BodyText
        dangerouslySetInnerHTML={{
          __html: footerColumn.bodyText,
        }}
      />
    </Wrapper>
  ) : (
    <Wrapper data-isfirst={isFirst} data-isLast={isLast} />
  );
}

const Wrapper = styled.div({
  flexBasis: '100%',
  maxWidth: '100%',
  wordBreak: 'break-word',
  paddingRight: '20px',
  marginBottom: theme.spacing(11),
  ...pseudo([':nth-child(n)[data-isfirst="true"]'], {
    marginTop: 0,
  }),
  ...pseudo([':nth-child(n)[data-isLast="true"]'], {
    marginTop: theme.spacing(3),
  }),
  ...media(mediaQuery.mediaMinSmall, {
    flexBasis: '70%',
    maxWidth: '70%',
  }),
  ...media(mediaQuery.mediaMinLarge, {
    flexBasis: '18%',
    maxWidth: '18%',
    marginBottom: 0,
    ...pseudo([':nth-child(n)[data-isfirst="true"]'], {
      flexBasis: '40%',
      maxWidth: '40%',
      padding: { x: 0 },
      marginRight: 'auto',
    }),
    ...pseudo([':nth-child(n)[data-isLast="true"]'], {
      margin: { x: 'auto' },
    }),
  }),
});

const Title = styled.h4({
  fontSize: '22px',
  letterSpacing: theme.tiny,
  lineHeight: theme.huge,
  color: theme.white,
  marginBottom: theme.spacing(5),
  ...media(mediaQuery.mediaMinLarge, { fontSize: theme.zeta }),
});

const List = styled.ul({
  listStyle: 'none',
  padding: {
    xy: 0,
  },
});

const ListItem = styled.li({
  margin: { top: theme.medium },
  letterSpacing: theme.letterSpacing.wide,
});

const Link = styled(KexLink, {
  font: { size: theme.moreLarge },
  textDecoration: 'underline',
  color: theme.white,
  ':hover': {
    textDecoration: 'none',
  },
});

const BodyText = styled.div({
  fontSize: theme.gamma,
  color: theme.white,
  lineHeight: '25.6px',
});

export default FooterList;
