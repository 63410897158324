import { query } from '@glitz/core';

type keyValueType = {
  [key: string]: any;
};

export const mediaQuery = {
  mediaMaxVerySmall: query({ maxWidth: '500px' }),
  mediaMinVerySmall: query({ minWidth: '500px' }),
  mediaMaxSmall: query({ maxWidth: '600px' }),
  mediaMinSmall: query({ minWidth: '600px' }),
  mediaMaxMedium: query({ maxWidth: '768px' }),
  mediaMinMedium: query({ minWidth: '768px' }),
  mediaMaxLarge: query({ maxWidth: '992px' }),
  mediaMinLarge: query({ minWidth: '992px' }),
  mediaMaxHuge: query({ maxWidth: '1130px' }),
  mediaMinHuge: query({ minWidth: '1130px' }),
  mediaMinGreat: query({ minWidth: '1300px' }),
  mediaMinScreen: query({ minWidth: '1440px' }),
  mediaMaxScreen: query({ maxWidth: '1440px' }),
};

export function mediaQuerySorter(a: string, b: string) {
  var typedMediaQueries = mediaQuery as keyValueType;
  const mediaQueryArray = Object.keys(typedMediaQueries).map(
    key => typedMediaQueries[key]
  );
  const indexA = mediaQueryArray.indexOf(a);
  const indexB = mediaQueryArray.indexOf(b);

  if (indexA === indexB) return 0;
  return indexA > indexB ? 1 : -1;
}
