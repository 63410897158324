import React from 'react';
import AppSettingsModel from '../Models/AppSettings.interface';

type PropType = {
  children: React.ReactNode;
  data: AppSettingsModel;
};

const AppSettingsContext = React.createContext({});

export const AppSettingsProvider = ({ children, data }: PropType) => {
  return (
    <AppSettingsContext.Provider value={data}>
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettingsData = () => {
  return React.useContext(AppSettingsContext) as AppSettingsModel;
};
