import React, { useEffect, useState, useRef } from 'react';
import { media, pseudo } from '@glitz/core';
import { mediaQuery } from '../Theme/mediaQueries';
import CookieIcon from '../Shared/Icons/CookieIcon';
import KexLink from '../Shared/KexLink/KexLink';
import { linkColor, primaryBlue, transparent } from '../Theme/colors';
import { styled, theme } from '../Theme';
import Divider from '../Shared/Divider/Divider';

const Cookies = ({ initData }) => {
  const props = {};
  const { appSettings, currentPage } = initData;
  const url = '';

  const [translations, setTranslations] = useState({
    display: true,
    heading: '',
    info: '',
    acceptButtonText: '',
    customSettingsButtonText: '',
    customSettingsHeading: '',
    customSettingsNecessaryHeading: '',
    customSettingsNecessaryInfo: '',
    customSettingsStatisticsHeading: '',
    customSettingsStatisticsInfo: '',
    customSettingsFunctionalHeading: '',
    customSettingsFunctionalInfo: '',
    customSettingsCloseButtonText: '',
    customSettingsSaveButtonText: '',
  });

  const [displayCookies, setDisplayCookies] = useState(false);
  const [showCustomSettings, setShowCustomSettings] = useState(false);
  const [submitCookiesModel, setSubmitCookiesModel] = useState({
    allowAllCookies: true,
    settings: {
      Statistics: false,
      Functional: false,
    },
  });

  useEffect(() => {
    const cookieUrl = `/api/${appSettings.languageRoute}/cookies/`;
    fetch(`${cookieUrl}GetCookie?webSiteSystemId=${currentPage.systemId}`)
      .then(res => res.json())
      .then(res => {
        if (res.displayCookiesPrompt == false) return;
        setTranslations(res);
        setDisplayCookies(true);
      });
  }, []);

  async function setStateAllowAllCookies(value) {
    setSubmitCookiesModel(prevState => ({
      ...prevState,
      allowAllCookies: value,
    }));
  }

  async function setStateSettings(setting) {
    const settings = { ...submitCookiesModel.settings };
    settings[setting] = !settings[setting];
    console.log(InputRef2);
    setSubmitCookiesModel(prevState => ({
      ...prevState,
      settings,
    }));
  }

  async function postConsent(allowAllCookies) {
    setSubmitCookiesModel(prevState => ({
      ...prevState,
      allowAllCookies: allowAllCookies,
    }));

    const cookieUrlPost = `/api/${appSettings.languageRoute}/cookies/`;
    await fetch(cookieUrlPost, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submitCookiesModel),
    })
      .then(res => {
        // If successful post, refresh
        window.location.reload();
      })
      .catch(e => {
        // Could display this in the cookie window if desirable
        console.warn(e);
      });

    setDisplayCookies(false);
  }

  const [hideInfoText, setInfoText] = useState(true);
  const SectionCookiesNotice = showCustomSettings
    ? SectionCookiesNotice2
    : SectionCookiesNotice1;

  const InputRef1 = useRef(null);
  const InputRef2 = useRef(null);

  return displayCookies ? (
    <SectionCookiesNoticeOuter>
      <SectionCookiesNotice>
        <CookiesNoticeContainer>
          <CookiesNoticeContent>
            <StyledCookieIcon>
              <CookieIcon />
            </StyledCookieIcon>
            <CookieHeading>{translations?.heading}</CookieHeading>
            <div>
              <BodyText
                dangerouslySetInnerHTML={{ __html: translations?.info }}
              />
            </div>
          </CookiesNoticeContent>
          {showCustomSettings == false && (
            <CookiesNoticeBtnContainer>
              <CookieBtn data-value="true" onClick={() => postConsent(true)}>
                {translations?.acceptButtonText}
              </CookieBtn>
              <CookieBtnSecondary
                onClick={() => {
                  setStateAllowAllCookies(false);
                  setShowCustomSettings(true);
                  setInfoText(false);
                }}
              >
                {translations?.customSettingsButtonText}
              </CookieBtnSecondary>
            </CookiesNoticeBtnContainer>
          )}
          {showCustomSettings == true && (
            <>
              <SectionCookiesNoticeSettings>
                <CookiesNoticeSettingsContainer>
                  <CookieSettingsHeading>
                    <CookieSettingsHeadingH3>
                      {translations?.customSettingsHeading}
                    </CookieSettingsHeadingH3>
                    <StyledDivider />
                  </CookieSettingsHeading>
                  <CookieSettingsContainer>
                    <CookieSettingsContent>
                      <CookieSettingToggle>
                        <SwitchLocked>
                          <SliderRoundLocked />
                        </SwitchLocked>
                      </CookieSettingToggle>
                      <CookieSettingText>
                        {translations?.customSettingsNecessaryHeading}
                        <CookieSettingTextP>
                          {translations?.customSettingsNecessaryInfo}
                        </CookieSettingTextP>
                      </CookieSettingText>
                    </CookieSettingsContent>
                  </CookieSettingsContainer>
                  <CookieSettingsContainer id="statistics">
                    <CookieSettingsContent>
                      <CookieSettingToggle>
                        <ToggleWrapper>
                          <CheckBox
                            ref={InputRef1}
                            type="checkbox"
                            data-functions-value="false"
                            onChange={() => setStateSettings('Statistics')}
                            id={'Input1'}
                          />
                          <CheckboxLabel
                            style={{
                              backgroundColor: InputRef1?.current?.checked
                                ? theme.primaryDarkBlue
                                : '#D7D2CB',
                            }}
                            htmlFor="Input1"
                          />
                          <CheckboxSlider
                            style={{
                              transform: InputRef1?.current?.checked
                                ? 'translateX(20px)'
                                : 'translateX(0px)',
                            }}
                          />
                        </ToggleWrapper>
                      </CookieSettingToggle>
                      <CookieSettingText>
                        {translations?.customSettingsStatisticsHeading}
                        <CookieSettingTextP>
                          {translations?.customSettingsStatisticsInfo}
                        </CookieSettingTextP>
                      </CookieSettingText>
                    </CookieSettingsContent>
                  </CookieSettingsContainer>
                  <CookieSettingsContainer id="functions">
                    <CookieSettingsContent>
                      <CookieSettingToggle>
                        <ToggleWrapper>
                          <CheckBox
                            ref={InputRef2}
                            type="checkbox"
                            data-functions-value="false"
                            onChange={() => setStateSettings('Functional')}
                            id={'Input2'}
                          />
                          <CheckboxLabel
                            style={{
                              backgroundColor: InputRef2?.current?.checked
                                ? theme.primaryDarkBlue
                                : '#D7D2CB',
                            }}
                            htmlFor="Input2"
                          />
                          <CheckboxSlider
                            style={{
                              transform: InputRef2?.current?.checked
                                ? 'translateX(20px)'
                                : 'translateX(0px)',
                            }}
                          />
                        </ToggleWrapper>
                      </CookieSettingToggle>
                      <CookieSettingText>
                        {translations?.customSettingsFunctionalHeading}
                        <CookieSettingTextP>
                          {translations?.customSettingsFunctionalInfo}
                        </CookieSettingTextP>
                      </CookieSettingText>
                    </CookieSettingsContent>
                  </CookieSettingsContainer>
                </CookiesNoticeSettingsContainer>
              </SectionCookiesNoticeSettings>
              <CookiesNoticeSettingsBtnContainer>
                <SettingsBtns>
                  <CookieBtnSecondary
                    onClick={() => {
                      setStateAllowAllCookies(true);
                      setShowCustomSettings(false);
                      setInfoText(true);
                    }}
                  >
                    {translations?.customSettingsCloseButtonText}
                  </CookieBtnSecondary>
                  <CookieBtn
                    data-value="false"
                    onClick={() => postConsent(false)}
                  >
                    {translations?.customSettingsSaveButtonText}
                  </CookieBtn>
                </SettingsBtns>
              </CookiesNoticeSettingsBtnContainer>
            </>
          )}
        </CookiesNoticeContainer>
      </SectionCookiesNotice>
    </SectionCookiesNoticeOuter>
  ) : (
    <div></div>
  );
};

const SectionCookiesNoticeOuter = styled.div({
  width: '100%',
  backgroundColor: 'rgba(0,0,0, 0.2)',
  position: 'fixed',
  inset: 0,
  margin: 'auto',
  zIndex: '10000',
});

const SectionCookiesNotice1 = styled.div({
  backgroundColor: theme.white,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '550px',
  maxWidth: '100%',
  margin: 'auto',
});

const SectionCookiesNotice2 = styled.div({
  backgroundColor: theme.white,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '750px',
  maxWidth: '100%',
  margin: 'auto',
});

const CookiesNoticeContainer = styled.div({
  padding: { x: '3rem', y: ' 2rem' },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  ...media(theme.mediaQuery.mediaMaxMedium, {
    width: '100%',
    marginLeft: 0,
    marginBottom: 0,
    overflowY: 'scroll',
    height: '80vh',
    padding: { x: '2rem', y: ' 1rem' },
  }),
});

const CookiesNoticeContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledCookieIcon = styled.div({
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
  backgroundColor: theme.primaryBlue,
  borderRadius: '50%',
  paddingTop: '11px',
  paddingLeft: '11px',
});

const CookieHeading = styled.h2({
  fontWeight: '600',
  fontSize: '1.5rem',
  lineHeight: '1.5rem',
  marginBottom: '1rem',
});

const BodyText = styled.div({
  marginBottom: '32px',
  lineHeight: '1.5rem',
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  ...media(theme.mediaQuery.mediaMinSmall, {
    lineHeight: '1.5rem',
  }),
});

const CookiesNoticeBtnContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '2rem',
  gap: '1rem',
});

const CookieBtn = styled.button({
  width: '100%',
  backgroundColor: theme.primaryBlue,
  color: theme.white,
  padding: { x: '3rem', y: '1rem' },
  cursor: 'pointer',
  fontWeight: '600',
  fontSize: '16px',
  ...media(theme.mediaQuery.mediaMaxMedium, {
    marginTop: '0.5rem',
    padding: { x: '2rem', y: '1rem' },
  }),
});

const CookieBtnSecondary = styled.button({
  width: '100%',
  backgroundColor: theme.white,
  border: { xy: { width: '1px', style: 'solid', color: theme.primaryBlue } },
  color: theme.primaryBlue,
  padding: { x: '3rem', y: '1rem' },
  cursor: 'pointer',
  fontWeight: '600',
  fontSize: '16px',
  ...media(theme.mediaQuery.mediaMaxMedium, {
    marginTop: '0.5rem',
    padding: { x: '2rem', y: '1rem' },
  }),
});

const SectionCookiesNoticeSettings = styled.div({
  backgroundColor: theme.white,
  inset: 0,
  width: '750px',
  maxWidth: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  margin: 'auto',
  ...media(theme.mediaQuery.mediaMaxMedium, {
    height: '60%',
  }),
});

const CookiesNoticeSettingsContainer = styled.div({
  background: '#F8F4F0',
  padding: '2rem',
  marginLeft: '-1rem',
  width: 'calc(100% + 2rem)',
  marginBottom: '2rem',
  ...media(theme.mediaQuery.mediaMaxMedium, {
    overflow: 'scroll',
  }),
});

const CookieSettingsHeading = styled.div({
  textAlign: 'center',
  marginBottom: '2rem',
});

const CookieSettingsHeadingH3 = styled.h3({
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight: '600',
  margin: '0',
  color: '#555555',
});

const StyledDivider = styled(Divider, {
  width: '100%',
  backgroundColor: theme.grayLine,
  margin: { y: theme.delta },
  ...media(mediaQuery.mediaMaxSmall, {
    margin: { y: theme.delta },
  }),
});

const CookieSettingsContainer = styled.div({
  display: 'flex',
  margin: { y: '1.5rem', x: '0px' },
});

const CookieSettingsContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const CookieSettingToggle = styled.div({
  marginRight: '10px',
  marginTop: '15px',
});

const SwitchLocked = styled.div({
  position: 'relative',
  display: 'inline-block',
  width: '44px',
  height: '24px',
  backgroundColor: theme.primaryBlue,
  opacity: '50%',
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
});

const SliderRoundLocked = styled.span({
  position: 'absolute',
  height: '18px',
  width: '18px',
  left: '22px',
  bottom: '3px',
  backgroundColor: theme.white,
  borderRadius: '50%',
});

const CookieSettingText = styled.h4({
  width: '100%,',
  margin: { y: '0px', x: '1rem' },
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '22px',
});

const CookieSettingTextP = styled.p({
  color: '#555555',
  fontSize: '16px',
  fontWeight: 500,
});

const CookiesNoticeSettingsBtnContainer = styled.div({});

const SettingsBtns = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
  ...media(theme.mediaQuery.mediaMaxMedium, {
    marginTop: '0.5rem',
  }),
});

const ToggleWrapper = styled.div({
  position: 'relative',
});

const CheckBox = styled.input({
  opacity: 0,
  zIndex: 1,
  borderRadius: '15px',
  width: '44px',
  height: '24px',
});

const CheckboxLabel = styled.label({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '44px',
  height: '24px',
  borderRadius: '15px',
  background: '#D7D2CB',
  cursor: 'pointer',
});

const CheckboxSlider = styled.span({
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  display: 'block',
  content: '""',
  borderRadius: '50%',
  width: '18px',
  height: '18px',
  margin: '3px',
  background: '#ffffff',
  transition: '0.2s',
});
export default Cookies;
